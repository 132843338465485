//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  validTrademarkNum,
  onlyStr,
  isEmpty,
  validApplicant,
  validateName,
  validNumber
} from "@/utils/validate";
import SvgIcon from "../components/SvgIcon.vue";
import {isNull} from "lodash";

export default {
  components: { SvgIcon },
  data() {
    return {
      loadding: false,
      searchText: "",
      searchSelect: 1,
      searchMenu: [
        {
          name: "近似查询",
          value: 1,
          placeholder: "检索商标名称，快速生成查询分析报告"
        },
        {
          name: "相同查询",
          value: 2,
          placeholder: "请输入商标名称"
        },
        {
          name: "商标号",
          value: 3,
          placeholder: "支持输入多个商标号，逗号或空格隔开；支持文本自动识别"
        },
        {
          name: "申请人",
          value: 4,
          placeholder: "输入申请人名称"
        },
        {
          name: "代理机构",
          value: 5,
          placeholder: "请输入代理机构名称"
        },
        {
          name: "商品服务",
          value: 6,
          placeholder: "输入商品/服务名称（支持非规范商品服务检索）"
        },
        {
          name: "公告期号",
          value: 7,
          placeholder: "请输入纯数字公告期号"
        }
      ],
      historys: {
        CKkeyword1: [],
        CKkeyword2: [],
        CKkeyword4: [],
        CKkeyword5: [],
        CKkeyword7: []
      }, //缓存搜索历史
      isLogin: false, // 是否登录
      isDisableBtn: false, // 防止重复提交
      crrCarousel1: null,
      crrCarouselPic1: null,
      crrCarousel2: null,
      crrCarouselPic2: null,
      crrCarousel3: null,
      carouselData1: [
        {
          content:
            "多维度进行近似商标查询，贴近审查规则，从操作源头获得精准商标数据",
          pic: require("~/static/icon/index/p1.png")
        },
        {
          content: "享受及时的商标公告更新，迅速通知客户，领先一步，抢占先机",
          pic: require("~/static/icon/index/p2.png")
        },
        {
          content:
            "实时且全面地监测客户商标，展示异议、续展、变更、撤三等业务线索，自动生成监测报告，解放双手",
          pic: require("~/static/icon/index/p3.png")
        },
        {
          content:
            "快捷导出excel商标查询结果，发布在线查询报告给客户，效率飙升",
          pic: require("~/static/icon/index/p4.png")
        },
        {
          content: "可视化观看目标客户的服务机构代理成功率，直观高效，知己知彼",
          pic: require("~/static/icon/index/p5.png")
        }
      ],
      carouselData2: [
        {
          content:
            "对新商标注册进行提前查询，通过飙局的AI智能查询报告，分析了解商标注册前景，提高成功率",
          pic: require("~/static/icon/index/p6.png")
        },
        {
          content:
            "自定义监控条件，及时发现侵权商标，维护企业品牌形象",
          pic: require("~/static/icon/index/p7.png")
        },
        {
          content:
            "盘点自身商标资产，分析行业标杆的商标布局，把控企业商标保护大局",
          pic: require("~/static/icon/index/p8.png")
        },
        {
          content:
            "可视化地查看代理机构的案量、注册、裁定成功率等数据情况，便于选择代理机构供应商",
          pic: require("~/static/icon/index/p9.png")
        }
      ],
      carouselData3: [
        {
          content: "了解全国各省市州、区县的商标申请注册趋势、商标类型占比、行业分布等，评估地方经济活动和创新水平"
        },
        {
          content: "分析各代理机构的商标申请数量、注册率、商标评审情况等，评估代理机构的业务规模、专业水平和服务质量"
        },
        {
          content:"识别被多次异议或无效宣告的申请人、代理机构，评估其是否存在不当行为或缺乏专业审查的情况",
        },
        {
          content: "识别区域内具潜力的企业商标，并进行重点监控与保护，促进区域品牌的成长和知名度提升",

        }
      ],
      carouselITl: null,
      popoverVisible: false,
      menuAlike: {
        typeSelect: [],
        classSelect: []
      } // 近似查询初始值
    };
  },

  async asyncData({ $axios, error }) {
    try {
      const [res, count, searchInit] = await Promise.all([
        $axios.get("/api/init/trademarkIndexInit"),
        $axios.get("/api/init/trademarkIndexCount"),
        $axios.get("/api/init/searchInit")
      ]);
      let _data = null;
      if (count.data.code == 0) {
        _data = count.data.data;
        _data.noticeNum = parseInt(parseInt(_data.noticeNum) / 10000); // 10358;
        _data.trademarkNum = parseInt(parseInt(_data.trademarkNum) / 10000);
        _data.agencyNum = parseInt(parseInt(_data.agencyNum) / 10000);
        _data.proposerNum = parseInt(parseInt(_data.proposerNum) / 10000);

        _data.noticeNum = _data.noticeNum.toLocaleString();
        _data.trademarkNum = _data.trademarkNum.toLocaleString();
        _data.agencyNum = _data.agencyNum.toLocaleString();
        _data.proposerNum = _data.proposerNum.toLocaleString();
      }
      const searchInitData = searchInit && searchInit.data && searchInit.data.data ? searchInit.data.data : {};
      return {
        initData: res.data.code == 0 ? res.data.data : null,
        searchInit: searchInitData,
        contData: _data
      };
    } catch (err) {
      error(err);
    }
  },
  async create() {
    // console.log(this.$route.query.redirectURL, "URL22222");
  },
  mounted() {
    if (!isEmpty(localStorage.getItem(this.$route.query.token))) {
      localStorage.setItem("uc_token", this.$route.query.token);
      setTimeout(() => {
        location.href = "/";
      }, 0);
      return;
    }
    let token = localStorage.getItem("uc_token");
    if (!isEmpty(token) && token != "undefined" && token != "null") {
      this.getUserInfo();
    } else {
      this.isLogin = false;
      localStorage.setItem("uc_token", "");
    }

    this.searchText = this.$cookies.get("searchKeyword");

    if(this.searchText && (typeof this.searchText !== 'string'))this.searchText = this.searchText.toString();
    let key1 = this.$cookies.get("CKkeyword1");
    let key2 = this.$cookies.get("CKkeyword2");
    let key4 = this.$cookies.get("CKkeyword4");
    let key5 = this.$cookies.get("CKkeyword5");
    let key7 = this.$cookies.get("CKkeyword7");
    this.historys = {
      CKkeyword1: key1 ? key1 : [],
      CKkeyword2: key2 ? key2 : [],
      CKkeyword4: key4 ? key4 : [],
      CKkeyword5: key5 ? key5 : [],
      CKkeyword7: key7 ? key7 : []
    }; //缓存搜索历史
    this.crrCarousel1 = 0;
    this.crrCarouselPic1 = this.carouselData1[this.crrCarousel1].pic;
    this.crrCarousel2 = 0;
    this.crrCarouselPic2 = this.carouselData1[this.crrCarousel2].pic;
    this.crrCarousel3 = 0;
    // this.scrollFun();
    // window.addEventListener("scroll", this.scrollFun, true);
    this.carouselTime();
    this.loadding = true

  },
  head() {
    return {
      title: "商标查询网，1秒查商标-飙局",
      meta: [
        {
          name: "description",
          content:
            "查商标，上飙局。普通检索快至1秒，支持图片查询、各字段二次筛选排序、查询报告1键分享；看公告，上飙局。近1亿商标公告图片，一眼洞悉商标动态，快人一步查侵权。"
        },
        {
          name: "keywords",
          content: "飙局、商标查询"
        }
      ]
    };
  },
  methods: {
    // 免费试用
    freeTrial(){
      this.$router.push('/login/register')
    },
    // 查看成功案例 -- 打开协会项目
    seeCase(){
      window.open('https://tm-analyse.chofn.com/#/trademarks', "_blank");
    },
    carouselTime() {
      clearInterval(this.carouselITl);
      this.carouselITl = setInterval(() => {
        if (this.crrCarousel1 == this.carouselData1.length - 1) {
          this.crrCarousel1 = 0;
        } else {
          this.crrCarousel1++;
        }
        if (this.crrCarousel2 == this.carouselData2.length - 1) {
          this.crrCarousel2 = 0;
        } else {
          this.crrCarousel2++;
        }
        if (this.crrCarousel3 == this.carouselData3.length - 1) {
          this.crrCarousel3 = 0;
        } else {
          this.crrCarousel3++;
        }
        this.crrCarouselPic1 = this.carouselData1[this.crrCarousel1].pic;
        this.crrCarouselPic2 = this.carouselData2[this.crrCarousel2].pic;
      }, 5000);
    },
    scrollFun() {
      let offsetTop = document.getElementById("dataScrollTop").offsetTop;
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      if (scrollTop > offsetTop - clientHeight) {
        this.runNumber();
      }
    },
    goCarouseIndex(index, type) {
      if (type == 1) {
        this.crrCarousel1 = index;
      } else if(type == 2){
        this.crrCarousel2 = index;
      } else {
        this.crrCarousel3 = index;
      }
      this.crrCarouselPic1 = this.carouselData1[this.crrCarousel1].pic;
      this.crrCarouselPic2 = this.carouselData2[this.crrCarousel2].pic;
    },
    getUserInfo() {
      this.$axios
        .post("/api/auth/getUserInfo", { isGoLogin: true })
        .then(res => {
          this.$store.commit("setUser", res.data.data);
          this.isLogin = res.data.code === 0;
        });
    },
    clickSearch() {
      this.isDisableBtn = true;
      setTimeout(() => {
        this.isDisableBtn = false;
      }, 2000); // 避免重复提交
      if((this.menuAlike.typeSelect.length > 0 && this.menuAlike.typeSelect.length < this.searchInit.approximate.menu.length)
        || (this.menuAlike.classSelect.length > 0 && this.menuAlike.classSelect.length < 45)){
        this.$cookies.set("menuAlike", this.menuAlike);
      }
      if (this.searchSelect == 1) {
        if (isEmpty(this.searchText)) {
          return this.$message("商标名称不能为空");
        }
        if (validApplicant(this.searchText) || onlyStr(this.searchText)) {
          this.$message("目前查询仅支持输入纯英文、纯数字或纯汉字");
          return;
        }
        if (this.searchText.length > 50) {
          return this.$message("商标名称不能多余50个字");
        }
      } else if (this.searchSelect == 2) {
        if (isEmpty(this.searchText)) {
          return this.$message("商标名称不能为空");
        }
        if (validApplicant(this.searchText)) {
          this.$message("目前查询仅支持输入英文、数字和汉字");
          return;
        }
        if (this.searchText.length > 50) {
          return this.$message("商标名称不能多余50个字");
        }
      } else if (this.searchSelect == 3) {
        if (!isEmpty(this.searchText)) {
          //识别商标号为字符串
          let tmNumArr = this.searchText.match(/\d+[a-z|A-Z]|[a-z|A-Z]?\d+/g);
          if (!isNull(tmNumArr)) {
            this.searchText = tmNumArr.toString();
            if (tmNumArr.length > 500) {
              return this.$confirm(
                "超过最大500查询量，仅展示前500个商标号结果",
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消"
                }
              ).then(() => {
                /*this.searchInfo();
                return;*/
              }).catch(() => { });
            }
          }
          /*if (!validTrademarkNum(this.searchText)) {
            return this.$message(
              "请输入正确的商标号格式，多个请用空格或逗号隔开"
            );
          }*/
        } else {
          return this.$message("商标号不能为空");
        }
      } else if (this.searchSelect == 4) {
        if (isEmpty(this.searchText)) {
          return this.$message("申请人不能为空");
        }
        if (validApplicant(this.searchText)) {
          return this.$message("请不要输入特殊符号");
        }
        if (this.searchText.length > 50) {
          return this.$message("申请人不能多余50个字");
        }
      } else if (this.searchSelect == 5) {
        //代理机构 弹框
        if (isEmpty(this.searchText)) {
          return this.$message("代理机构不能为空");
        }
        if (validApplicant(this.searchText)) {
          return this.$message("请不要输入特殊符号");
        }
        if (this.searchText.length > 50) {
          return this.$message("代理机构名称不能多余50个字");
        }
      } else if (this.searchSelect == 6) {
        //商品服务
        if (isEmpty(this.searchText)) {
          return this.$message("商品名称不能为空");
        }
        if (validApplicant(this.searchText)) {
          return this.$message("目前查询仅支持输入英文、数字和汉字");
        }
        return this.$router.push("/classify/search?v=" + this.searchText);
      } else if (this.searchSelect == 7) {
        if (isEmpty(this.searchText)) {
          return this.$message("公告期号不能为空");
        }
        //公告期号
        if (!validNumber(this.searchText)) {
          return this.$message("请输入正确的公告期号");
        }
        return this.$router.push("/notice?number=" + this.searchText);
      }
      this.$cookies.set("searchKeyword", this.searchText);
      if(!this.isLogin && this.searchSelect != 5){
        // 未登录跳转页面
        return this.$router.push(
          this.tools.trademarkSearchTouristUrl(this.searchText, this.searchSelect)
        );
      } else {
        this.$cookies.set("showInto", false)
        // 登录跳转页面
        return this.$router.push(
          this.tools.trademarkSearchUrl(this.searchText, this.searchSelect)
        );
      }

    },
    querySearch(queryString, cb) {
      var historys = [];
      if (this.searchSelect == 1) {
        historys = this.historys.CKkeyword1;
      } else if (this.searchSelect == 2) {
        historys = this.historys.CKkeyword2;
      } else if (this.searchSelect == 4) {
        historys = this.historys.CKkeyword4;
      } else if (this.searchSelect == 5) {
        historys = this.historys.CKkeyword5;
      }
      var results = queryString
        ? historys.filter(this.createFilter(queryString))
        : historys;
      // 调用 callback 返回建议列表的数据
      // let arr = [];
      // if (results) {
      //   for (var i = 0, len = results.length; i < len; i++) {
      //     arr.push({ value: results[i] });
      //   }
      // }
      let arr = results.map(res => {
        return {
          value: res
        };
      });

      cb(arr);
    },
    createFilter(queryString) {
      return restaurant => {
        if (restaurant) {
          return (
            restaurant.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          );
        } else {
          return false;
        }
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    filterConfirmAlike(param){
      this.menuAlike = param
      this.popoverVisible = false;
    }
  },
  beforeDestroy() {
    clearInterval(this.carouselITl);
    this.carouselITl = null;
  }
};
