import { render, staticRenderFns } from "./index.vue?vue&type=template&id=045484f7&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=045484f7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "045484f7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/chofn/components/Header.vue').default,SearchAlike: require('/home/chofn/components/search/SearchAlike.vue').default,Footer: require('/home/chofn/components/Footer.vue').default})
